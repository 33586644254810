import React from "react"
import { Link } from "gatsby"
import StoreLayout from "../components/StoreLayout"

const PaymentSuccess = () => {
  return (
    <StoreLayout>
      <div className="container section center">
        <h1>Payment Success!</h1>
        <p>We need some information from you and we will send you an email with instructions.</p>
        <p>Please contact us if you haven't received it in an hour.</p>
        <Link to="/"><button>Back To Home Page</button></Link>
      </div>
    </StoreLayout>
  )
}

export default PaymentSuccess